// loading.js
let loadingElement = null;

// // 转圈
export function showLoading() {
  // 显示加载动画
  const el = document.createElement('div');
  el.setAttribute('id', 'my-loading');
  el.innerHTML = `
    <div class="my-loading-background">
      <div class="my-loading-spinner"></div>
    </div>`;
  document.body.appendChild(el);
  loadingElement = el;
}

export function closeLoading() {
  // 关闭加载动画
  if (loadingElement) {
    document.body.removeChild(loadingElement);
    loadingElement = null;
  }
}